import moment from "moment";

export namespace User {
  export interface Type {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    savedRoutes: number;
    visitedPlaces: number;
    favouritePlaces: number;
    providerType: "google" | "facebook" | "local";
    activated: boolean;
    deletedAt?: moment.Moment;
    createdAt?: moment.Moment;
  }

  export const parse = function (data: any): User.Type {
    return {
      id: data.uuid,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      savedRoutes: data.saved_routes.length,
      visitedPlaces: data.visited_places.length,
      favouritePlaces: data.favourite_places.length,
      providerType: data.provider_type,
      activated: data.activated,
      createdAt: data.created_at ? moment(data.created_at) : undefined,
      deletedAt: data.deleted_at ? moment(data.deleted_at) : undefined,
    };
  };
}

export default User;
