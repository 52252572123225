import React from "react";
import FeedbacksPage from "./Feedbacks";
import UsersPage from "./Users";

const Statistics: React.FC = () => {
  return (
    <div>
      <FeedbacksPage />
      <UsersPage />
    </div>
  );
};

export default Statistics;
