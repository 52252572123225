import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import React, { useEffect, useMemo, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import { getPlaces } from "../../api/places";
import { GetPlaceEntity } from "../../entities/place";
import CircularProgress from "@material-ui/core/CircularProgress";
import RouteEntity from "../../entities/routeEntity";
import { postNewRoute } from "../../api/routes";
import { useHistory } from "react-router";

const styles = makeStyles({
	paper: {
		width: "60%",
		height: "auto",
		minHeight: 500,
		padding: 20,
		margin: "15vh auto",
	},
	form: {
		width: "60%;",
		height: 300,
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		margin: "5vh auto",
	},
	buttonControl: {
		width: "50%",
		margin: "30px auto",
	},
	selectText: {
		textAlign: "left",
	},
	error: {
		width: "100%",
		padding: 10,
		background: "#FC8981",
		border: "2px solid #D32F2F",
		color: "#fff",
		textAlign: "left",
		borderRadius: 5,
	},
});

const AddRoute: React.FC = () => {
	const [error, setError] = useState<{ hasError: boolean; message: string }>({
		hasError: false,
		message: "",
	});
	const [routeName, setRouteName] = useState<string>("");
	const [placeIds, setPlaceIds] = useState<string[]>([]);
	const [places, setPlaces] = useState<GetPlaceEntity[]>();
	const classes = styles();
	const history = useHistory();

	useEffect(() => {
		getPlaces()
			.then((data) => setPlaces(data))
			.catch((error) => {
				setError({
					hasError: true,
					message: error.message,
				});
				setTimeout(() => {
					setError({ hasError: false, message: "" });
					history.push("/main");
				}, 2000);
			});
	}, []);

	const handleSubmitRouteForm = (e: any) => {
		e.preventDefault();
		if (routeName !== "" && placeIds.length > 1) {
			const route: RouteEntity = {
				title: routeName,
				places: placeIds,
			};
			postNewRoute(route)
				.then((response) => {
					console.log(response);
				})
				.catch((error) => {});

			setPlaceIds([]);
			matchedNames = [];
			setRouteName("");
			setError({ hasError: false, message: "" });
		} else if (routeName === "" && placeIds.length > 1) {
			setError({
				hasError: true,
				message: "Please enter valid name for title! ",
			});
			setTimeout(() => {
				setError({ hasError: false, message: "" });
			}, 3000);
		} else {
			setError({
				hasError: true,
				message: "Please enter two or more places for one route! ",
			});
			setTimeout(() => {
				setError({ hasError: false, message: "" });
			}, 3000);
		}
	};

	let matchedNames = useMemo(() => {
		const matches: string[] = [];
		if (places) {
			places.map((place) => {
				placeIds.map((placeId) => {
					if (placeId === place.uuid) {
						matches.push(place.name);
					}
				});
			});
		}
		return matches;
	}, [placeIds, places]);

	console.log(placeIds);

	return (
		<Paper className={classes.paper}>
			{error.hasError ? (
				<div className={classes.error}>
					<p>{error.message}</p>
				</div>
			) : null}
			{places ? (
				<React.Fragment>
					<h1>Add route</h1>
					<form className={classes.form} onSubmit={handleSubmitRouteForm}>
						<FormControl>
							<TextField
								error={error.hasError}
								label="Title"
								type="text"
								name="title"
								onChange={(
									e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
								) => setRouteName(e.target.value)}
								value={routeName}
							/>
						</FormControl>
						<FormControl>
							<Select
								error={error.hasError}
								multiple
								value={placeIds}
								onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
									setPlaceIds(event.target.value as string[])
								}
								renderValue={() => (matchedNames as string[]).join(", ")}
								input={<Input />}
							>
								{places.map((place) => (
									<MenuItem key={place.uuid} value={place.uuid}>
										<Checkbox checked={matchedNames.indexOf(place.name) > -1} />
										<ListItemText primary={place.name} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl>
							<Button type="submit" variant="contained" color="primary">
								Submit
							</Button>
						</FormControl>
					</form>
				</React.Fragment>
			) : (
				<CircularProgress size={80} />
			)}
		</Paper>
	);
};

export default AddRoute;
//matchedNames.indexOf(place.name) > -1
//(selected as string[]).join(", ")
