import RouteEntity from "../entities/routeEntity";
import axios from "axios";

export function postNewRoute(route: RouteEntity) {
  return new Promise<RouteEntity>((resolve, reject) => {
    axios
      .post("/routes/generate-admin", route)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
