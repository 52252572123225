import axios from "axios";
import { ACCESS_TOKEN_KEY, BASE_URL } from "../constants";
import { MAIN_PATH } from "../routes";

let API_REQUEST_INTERCEPTOR_ID: number;
let API_RESPONSE_INTERCEPTOR_ID: number;

axios.defaults.baseURL = `${BASE_URL}/api`;
console.log("[BaseUrl] -> ", `${BASE_URL}/api`);
function interceptApiRequest() {
  return axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(ACCESS_TOKEN_KEY);

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
}

function interceptApiResponse() {
  return axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const { response } = error;
      const urlApiIndex = response ? response.config.url.indexOf("/api") : 0;

      if (response && urlApiIndex !== 0) {
        if (response.status === 401) {
          localStorage.removeItem(ACCESS_TOKEN_KEY);
          window.location.assign(MAIN_PATH);
        }
      }
      return Promise.reject(error);
    }
  );
}

export const setInterceptApiResponse = function () {
  if (API_RESPONSE_INTERCEPTOR_ID === undefined) {
    API_RESPONSE_INTERCEPTOR_ID = interceptApiResponse();
  }
};

export const requireBearer = function () {
  if (API_REQUEST_INTERCEPTOR_ID === undefined) {
    API_REQUEST_INTERCEPTOR_ID = interceptApiRequest();
  }
};

export const login = function (
  email: string,
  password: string
): Promise<{ token: string }> {
  return new Promise<{ token: string }>((resolve, reject) => {
    axios
      .post("/login/admin", { email, password })
      .then(({ data }) => {
        resolve({ token: data.token });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
