import axios from "axios";
import MonthlyAccentEntity from "../entities/monthlyAccent";

export function postMonthlyAccent(data: MonthlyAccentEntity): Promise<any> {
  let parsedData = {
    month: data.month,
    blog_post_id: data.blogPostId,
  };
  return new Promise<void>((resolve, reject) => {
    axios.post("/monthly-accent", parsedData).catch((error) => reject(error));
  });
}
