import React from "react";

import {
	Dialog,
	Button,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormControl,
	TextField,
	Select,
	MenuItem,
} from "@material-ui/core";
import Category from "../../entities/category";

import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles({
	dialog: {
		width: "100%",
		height: 350,
	},
	formContainer: {
		width: "75%",
		margin: "0 auto",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	formControl: {
		width: "50%",
		padding: 0,
		marginTop: "20px",
		height: 250,
	},
	selectText: {
		textAlign: "left",
	},
});

interface Props {
	open: boolean;
	onCloseModal?: React.ReactEventHandler<{}>;
	onNameChange: Function;
	onSubmitHandler: React.ReactEventHandler;
	categories: Category[] | null;
	values: {
		name: string;
		categoryId: string;
	};
	error: boolean;
}

const subcategoryDialog: React.FC<Props> = (props) => {
	const classes = styles();
	let categoryItems;

	if (props.categories !== null) {
		categoryItems = props.categories.map((category: any) => {
			return (
				<MenuItem key={category.id + category.name} value={category.id}>
					{category.name}
				</MenuItem>
			);
		});
	}
	return (
		<Dialog
			onClose={props.onCloseModal}
			open={props.open}
			className={classes.dialog}
			maxWidth="sm"
			fullWidth>
			<DialogTitle>Add new Sub-category</DialogTitle>
			<DialogContent className={classes.formContainer}>
				<FormControl className={classes.formControl}>
					<TextField
						error={props.error}
						label="Name"
						type="text"
						onChange={(e) =>
							props.onNameChange({ ...props.values, name: e.target.value })
						}
						value={props.values.name}
					/>
				</FormControl>
				<FormControl className={classes.formControl}>
					<Select
						label="category"
						name="category"
						value={props.values.categoryId}
						onChange={(e) =>
							props.onNameChange({
								...props.values,
								categoryId: e.target.value,
							})
						}
						className={classes.selectText}>
						<MenuItem disabled>Category</MenuItem>
						{categoryItems}
					</Select>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={props.onSubmitHandler}
					color="primary"
					variant="contained"
					type="submit">
					Submit
				</Button>
				<Button onClick={props.onCloseModal} color="primary">
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default subcategoryDialog;
