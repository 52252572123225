import React, { useMemo } from "react";

import {
	Button,
	FormControl,
	TextField,
	MenuItem,
	Paper,
	Select,
	Checkbox,
	FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import CategoryEntity from "../../entities/category";
import SubcategoryEntity from "../../entities/subcategory";
import RegionEntity from "../../entities/region";
import PlaceEntity from "../../entities/place";
import classNames from "classnames";

interface PostErrorEntity {
	error: boolean;
	message: string;
}

interface Props {
	place: PlaceEntity;
	onInputChangeHandler: Function;
	onSubmitForm: Function;
	categories: CategoryEntity[];
	subCategories: SubcategoryEntity[];
	onPictureNameInputChange: Function;
	regions: RegionEntity[];
	onSubmitPictureName: Function;
	pictureValue: string;
	error: boolean;
	onCheck: boolean;
	onCheckChange: Function;
}

const styles = makeStyles({
	paper: {
		width: "40%",
		height: "auto",
		minHeight: 500,
		padding: 20,
		margin: "15vh auto",
	},
	form: {
		width: "90%;",
		display: "flex",
		flexDirection: "column",
		margin: "5vh auto",
	},
	formControl: {
		marginTop: 20,
	},
	buttonControl: {
		width: "50%",
		margin: "30px auto",
	},
	selectText: {
		textAlign: "left",
	},
	error: {
		width: "100%",
		padding: 10,
		background: "#FC8981",
		border: "2px solid #D32F2F",
		color: "#fff",
		textAlign: "left",
		borderRadius: 5,
	},
});

const AddPlace: React.FC<Props> = (props) => {
	const classes = styles();

	let categoryItems = null;
	categoryItems = useMemo(() => {
		if (props.categories !== undefined) {
			return props.categories.map((category: any) => {
				return (
					<MenuItem key={category.id + category.name} value={category.id}>
						{category.name}
					</MenuItem>
				);
			});
		}
	}, [props.categories]);

	let subCategoryItems = null;
	subCategoryItems = useMemo(() => {
		if (props.subCategories !== undefined) {
			return props.subCategories.map((subCategory: any) => {
				return (
					<MenuItem
						key={subCategory.id + subCategory.name}
						value={subCategory.id}
					>
						{subCategory.name}
					</MenuItem>
				);
			});
		}
	}, [props.subCategories]);

	let regionItems = null;
	regionItems = useMemo(() => {
		if (props.regions !== undefined) {
			return props.regions.map((region: any) => {
				return (
					<MenuItem key={region.id + region.name} value={region.id}>
						{region.name}
					</MenuItem>
				);
			});
		}
	}, [props.regions]);

	return (
		<Paper className={classNames("place-page", classes.paper)}>
			<h1>Add place</h1>
			<form className={classes.form} onSubmit={(e) => props.onSubmitForm(e)}>
				<FormControl className={classes.formControl}>
					<TextField
						error={props.error}
						label="Name"
						type="text"
						name="name"
						onChange={(
							e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
						) => props.onInputChangeHandler(e)}
						value={props.place!.name}
					/>
				</FormControl>
				<FormControl className={classes.formControl}>
					<TextField
						error={props.error}
						label="Description"
						type="text"
						name="description"
						onChange={(
							e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
						) => props.onInputChangeHandler(e)}
						value={props.place!.description}
					/>
				</FormControl>
				<FormControl>
					<TextField
						error={props.error}
						label="Cover photo"
						type="text"
						name="coverPhoto"
						onChange={(
							e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
						) => props.onInputChangeHandler(e)}
						value={props.place.coverPhoto}
					/>
				</FormControl>

				<FormControl className={classes.formControl}>
					<TextField
						error={props.error}
						label="Picture Name"
						type="text"
						name="picture"
						onChange={(
							e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
						) => props.onPictureNameInputChange(e.target.value)}
						value={props.pictureValue}
					/>
					<Button
						type="button"
						variant="outlined"
						color="primary"
						disabled={props.pictureValue === "" ? true : false}
						onClick={(e) => props.onSubmitPictureName(e)}
					>
						Add picture name
					</Button>
				</FormControl>
				<FormControl className={classes.formControl}>
					<FormControl>
						<TextField
							error={props.error}
							label="Latitude"
							type="text"
							name="latitude"
							onChange={(
								e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
							) => props.onInputChangeHandler(e)}
							value={props.place!.location.latitude.toString()}
						/>
					</FormControl>
					<FormControl>
						<TextField
							error={props.error}
							label="Longitude"
							type="text"
							name="longitude"
							onChange={(
								e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
							) => props.onInputChangeHandler(e)}
							value={props.place!.location.longitude.toString()}
						/>
					</FormControl>
				</FormControl>
				<FormControl className={classes.formControl}>
					<Select
						error={props.error}
						label="Region"
						name="regionId"
						value={props.place.regionId}
						onChange={(e) => props.onInputChangeHandler(e)}
						className={classes.selectText}
						defaultValue
					>
						<MenuItem disabled>Regions</MenuItem>
						{regionItems}
					</Select>
				</FormControl>
				<FormControl className={classes.formControl}>
					<Select
						error={props.error}
						label="Category"
						name="categoryId"
						value={props.place.categoryId}
						onChange={(e) => props.onInputChangeHandler(e)}
						className={classes.selectText}
					>
						<MenuItem disabled>Category</MenuItem>
						{categoryItems}
					</Select>
				</FormControl>
				<FormControl className={classes.formControl}>
					{props.place.categoryId !== "" ? (
						<Select
							error={props.error}
							label="Subcategory"
							name="subcategoryId"
							value={
								props.place.subcategoryId === ""
									? "Category"
									: props.place.subcategoryId
							}
							onChange={(e) => props.onInputChangeHandler(e)}
							className={classes.selectText}
						>
							<MenuItem disabled>Subcategory</MenuItem>
							{subCategoryItems}
						</Select>
					) : null}
				</FormControl>
				<FormControl className={classes.formControl}>
					<FormControlLabel
						control={
							<Checkbox
								checked={props.onCheck}
								onChange={() => props.onCheckChange(!props.onCheck)}
							/>
						}
						label="Изключване от маршрут"
					/>
				</FormControl>
				<FormControl className={classes.formControl}>
					<Button type="submit" variant="contained" color="primary">
						Submit
					</Button>
				</FormControl>
			</form>
		</Paper>
	);
};

export default AddPlace;
