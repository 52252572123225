import moment from "moment";

export namespace Feedback {
  export type ContextType =
    | "general"
    | "registration"
    | "save_random_route"
    | "save_generated_route"
    | "visit_place"
    | "add_favourite_place";
  export interface Author {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  }
  export interface Type {
    id: string;
    text: string;
    author?: Author;
    rating: number;
    context: ContextType;
    createdAt?: moment.Moment;
  }

  export const parse = function (data: any): Feedback.Type {
    return {
      id: data.uuid,
      text: data.text,
      author: data.author ? parseAuthor(data.author) : undefined,
      rating: data.rating,
      context: data.context,
      createdAt: data.created_at ? moment(data.created_at) : undefined,
    };
  };

  export const parseAuthor = function (data: any): Author {
    return {
      id: data.uuid,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
    };
  };
}

export default Feedback;
