import React, { useEffect, useState } from "react";
import { getCategories } from "../../api/categories";
import { postPlace } from "../../api/places";
import { getRegions } from "../../api/regions";
import { getCurrentSubcategories } from "../../api/subcategories";
import CategoryEntity from "../../entities/category";
import PlaceEntity from "../../entities/place";
import RegionEntity from "../../entities/region";
import SubcategoryEntity from "../../entities/subcategory";
import AddPlace from "./AddPlace";

export const Page: React.FC = () => {
  const [place, setPlace] = useState<PlaceEntity>({
    name: "",
    description: "",
    regionId: "",
    categoryId: "",
    subcategoryId: "",
    addToRoute: false,
    pictures: [],
    coverPhoto: "",
    location: {
      latitude: 0,
      longitude: 0,
    },
  });

  const [error, setError] = useState<boolean>(false);
  const [categoryState, setCategoryState] = useState<CategoryEntity[]>();
  const [subcategoryState, setSubcategoryState] =
    useState<SubcategoryEntity[]>();
  const [regionState, setRegionState] = useState<RegionEntity[]>();
  const [pictureState, setPictureState] = useState<string[]>([]);
  const [addToRoute, setAddToRoute] = useState<boolean>(false);
  const [pictureNameState, setPictureNameState] = useState<string>("");

  const checkValidacity = () => {
    let isValid = true;

    if (
      place.name === "" ||
      place.description === "" ||
      place.location.latitude === 0 ||
      place.location.longitude === 0 ||
      place.regionId === "" ||
      place.coverPhoto === "" ||
      place.categoryId === "" ||
      place.subcategoryId === ""
    ) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
      isValid = false;
    }
    return isValid;
  };

  const addPictureHandler = (e: any): void => {
    e.preventDefault();
    let newArr: string[] = pictureState;

    newArr.push(pictureNameState);

    setPictureState(newArr);
    setPictureNameState("");
  };

  const submitFormHandler = (e: any) => {
    e.preventDefault();

    if (checkValidacity()) {
      let data: PlaceEntity = {
        ...place,
        addToRoute: addToRoute,
        pictures: pictureState,
        location: {
          longitude: +place.location.longitude,
          latitude: +place.location.latitude,
        },
      };
      postPlace(data).catch((error) => {
        console.error(error);
      });
      clearFields();
    }
  };

  const inputChangedHandler = (e: any) => {
    let newObj: PlaceEntity;
    if (e.target.name === "longitude" || e.target.name === "latitude") {
      newObj = {
        ...place,
        location: {
          ...place.location,
          [e.target.name]: e.target.value,
        },
      };
    } else {
      newObj = {
        ...place,
        [e.target.name]: e.target.value,
      };
    }
    setPlace(newObj);
  };

  const clearFields = () => {
    let newObj: PlaceEntity = {
      name: "",
      description: "",
      pictures: [],
      coverPhoto: "",
      addToRoute: true,
      location: {
        longitude: 0,
        latitude: 0,
      },
      regionId: "",
      categoryId: "",
      subcategoryId: "",
    };
    setPictureState([]);
    setPlace(newObj);
  };
  const fetchCategories = () => {
    getCategories()
      .then((category) => {
        setCategoryState(category);
      })
      .catch((error) => {
        console.error(error);
        alert("Something went wrong!");
      });
  };
  const getSubcategories = (categoryId: string) => {
    getCurrentSubcategories(categoryId)
      .then((subcategory) => {
        setSubcategoryState(subcategory);
      })
      .catch((error: any) => {
        console.error(error);
        alert("Something went wrong!");
      });
  };
  const fetchRegions = () => {
    getRegions()
      .then((region) => {
        setRegionState(region);
      })
      .catch((error) => {
        console.error(error.message);
        alert("Something went wrong!");
      });
  };

  useEffect(() => {
    if (place.categoryId) {
      getSubcategories(place.categoryId);
    }
  }, [place.categoryId]);

  useEffect(() => {
    fetchCategories();
    fetchRegions();
  }, []);

  if (!categoryState && !subcategoryState && !regionState) {
    return <div>Loading...</div>;
  }
  return (
    <AddPlace
      place={place!}
      onInputChangeHandler={inputChangedHandler}
      onSubmitForm={submitFormHandler}
      onSubmitPictureName={addPictureHandler}
      onPictureNameInputChange={setPictureNameState}
      pictureValue={pictureNameState}
      categories={categoryState!}
      subCategories={subcategoryState!}
      regions={regionState!}
      onCheck={addToRoute}
      onCheckChange={setAddToRoute}
      error={error}
    />
  );
};
