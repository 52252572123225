import axios from "axios";
import User from "../entities/User";

export function fetchAllUsers(): Promise<User.Type[]> {
  return new Promise<User.Type[]>((resolve, reject) => {
    axios
      .get(
        "/users?exclude_fields=provider_token&exclude_fields=role&exclude_fields=profile_picture&exclude_fields=feedbacks"
      )
      .then(({ data: { result } }) => {
        resolve(result.map(User.parse));
      })
      .catch((error) => reject(error));
  });
}
