import axios from "axios";
import Category from "../entities/category";

export function getCategories(): Promise<Category[]> {
  return new Promise<Category[]>((resolve, reject) => {
    axios
      .get("/categories")
      .then(({ data }) => {
        let state: Category[] = [];
        data.result.map((category: any) => {
          return state.push({
            name: category.name,
            id: category.uuid,
          });
        });
        resolve(state);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function postCategory(data: string): Promise<any> {
  return new Promise<void>((resolve, reject) => {
    axios
      .post("/categories", { name: data })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => reject(error));
  });
}
