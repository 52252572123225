import axios from "axios";
import SubcategoryEntity from "../entities/subcategory";

export function getCurrentSubcategories(
  categoryId: string
): Promise<SubcategoryEntity[]> {
  return new Promise<SubcategoryEntity[]>((resolve, reject) => {
    axios
      .get(`/subcategories/?category_id=${categoryId}`)
      .then(({ data }) => {
        const transformedData: SubcategoryEntity[] = [];
        data.result.map((subcategory: any) => {
          return transformedData.push({
            name: subcategory.name,
            id: subcategory.uuid,
          });
        });
        resolve(transformedData);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function postSubcategory(data: {
  name: string;
  categoryId: string;
}): Promise<any> {
  const parsedData = {
    name: data.name,
    category_id: data.categoryId,
  };
  return new Promise<void>((resolve, reject) => {
    axios
      .post("/subcategories", parsedData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
