import { Typography } from "@material-ui/core";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { fetchAllFeedbacks } from "../../api/feedback";
import Feedback from "../../entities/Feedback";

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "text", headerName: "Text", flex: 1 },
  { field: "rating", headerName: "Rating", width: 120, type: "number" },
  { field: "context", headerName: "Context", flex: 1 },
  {
    field: "author",
    headerName: "Author ID",
    flex: 1,
    editable: true,
    valueGetter: (params: GridValueGetterParams) => {
      const author = params.row["author"];
      if (author) {
        return author["id"];
      }
      return "";
    },
  },
  {
    field: "author.firstName",
    headerName: "First name",
    flex: 1,
    editable: true,
    valueGetter: (params: GridValueGetterParams) => {
      const author = params.row["author"];
      if (author) {
        return author["firstName"];
      }
      return "";
    },
  },
  {
    field: "author.lastName",
    headerName: "Last name",
    flex: 1,
    editable: true,
    valueGetter: (params: GridValueGetterParams) => {
      const author = params.row["author"];
      if (author) {
        return author["lastName"];
      }
      return "";
    },
  },
  {
    field: "author.email",
    headerName: "Email",
    flex: 1,
    editable: true,
    valueGetter: (params: GridValueGetterParams) => {
      const author = params.row["author"];
      if (author) {
        return author["email"];
      }
      return "";
    },
  },
  { field: "createdAt", headerName: "Created At", flex: 1, type: "dateTime" },
];

const FeedbacksPage: React.FC = () => {
  const [allFeedback, setAllFeedback] = useState<Feedback.Type[]>([]);
  useEffect(() => {
    fetchAllFeedbacks().then((result) => {
      setAllFeedback(result);
    });
  }, []);
  return (
    <div style={{ width: "100%", margin: "16px 0" }}>
      <Typography variant="h5">Feedback</Typography>
      <DataGrid rows={allFeedback} columns={columns} autoHeight autoPageSize />
    </div>
  );
};

export default FeedbacksPage;
