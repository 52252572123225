import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router";

import { login } from "../../api/login";
import * as routes from "../../routes";
import { ACCESS_TOKEN_KEY } from "../../constants";
import { EMAIL_EXPRESSION } from "../../constants";

import { FormControl, TextField, Button, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const styles = makeStyles({
	paper: {
		width: "40%",
		height: "auto",
		minHeight: 500,
		padding: 20,
		margin: "15vh auto",
	},
	form: {
		width: "70%;",
		display: "flex",
		flexDirection: "column",
		margin: "15vh auto",
	},
	formControl: {
		marginTop: 30,
	},
	buttonControl: {
		width: "50%",
		margin: "30px auto",
	},
	error: {
		width: "100%",
		padding: 10,
		background: "#FC8981",
		border: "2px solid #D32F2F",
		color: "#fff",
		textAlign: "left",
		borderRadius: 5,
	},
});

interface LoginEntity {
	email: string;
	password: string;
}
const LoginPage: React.FC = () => {
	const classes = styles();

	const history = useHistory();
	const [userData, setUserData] = useState({
		email: "",
		password: "",
	});
	const [error, setError] = useState<string>("");

	useEffect(() => {
		const xAccessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
		if (xAccessToken) {
			history.push(routes.MAIN_PATH);
		}
	}, [history]);

	const handleInputChange = useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
			let newObj: LoginEntity = {
				...userData,
				[e.target.name]: e.target.value,
			};
			setUserData(newObj);
		},
		[userData]
	);

	const checkValidacity = () => {
		let regExp = new RegExp(EMAIL_EXPRESSION);

		if (!regExp.test(userData.email)) {
			setError("Please enter a valid email!");
			setTimeout(() => {
				setError("");
			}, 3000);
		}

		let passwordIsValid = false;
		if (userData.password !== "") {
			passwordIsValid = true;
		} else {
			setError("Please enter a valid password!");
			setTimeout(() => {
				setError("");
			}, 3000);
		}

		return regExp.test(userData.email) && passwordIsValid;
	};

	const submitButtonHandler = (e: any) => {
		e.preventDefault();

		if (checkValidacity()) {
			login(userData.email, userData.password)
				.then((data: any) => {
					localStorage.setItem(ACCESS_TOKEN_KEY, data.token);
					history.push(routes.MAIN_PATH);
				})
				.catch((error) => {
					setError("Invalid email or password !");
					setTimeout(() => {
						setError("");
					}, 3000);
				});
		}
	};

	return (
		<React.Fragment>
			<Paper variant="outlined" className={classes.paper}>
				<h1>Login Page</h1>
				<div
					className={classes.error}
					style={{ display: `${error !== "" ? "block" : "none"}` }}
				>
					{error}
				</div>
				<form className={classes.form} onSubmit={(e) => submitButtonHandler(e)}>
					<FormControl>
						<TextField
							error={error !== "" ? true : false}
							label="Email"
							type="email"
							name="email"
							onChange={handleInputChange}
						/>
					</FormControl>
					<FormControl className={classes.formControl}>
						<TextField
							error={error !== "" ? true : false}
							label="Password"
							type="password"
							name="password"
							onChange={handleInputChange}
						/>
					</FormControl>
					<FormControl
						className={classNames(classes.formControl, classes.buttonControl)}
					>
						<Button type="submit" variant="contained" color="primary">
							Submit
						</Button>
					</FormControl>
				</form>
			</Paper>
		</React.Fragment>
	);
};
export default LoginPage;
