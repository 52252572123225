import React from "react";

import {
	Dialog,
	Button,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormControl,
	TextField,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles({
	dialog: {
		width: "100%",
		height: 500,
	},
	formContainer: {
		width: "75%",
		margin: "0 auto",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	formControl: {
		width: "100%",
		padding: 0,
		marginTop: "20px",
	},
});

interface Props {
	open: boolean;
	onCloseModal?: React.ReactEventHandler<{}>;
	inputValues: {
		name: string;
		location: { longitude: string; latitude: string };
	};
	onInputChange: Function;
	onSubmitHandler: React.ReactEventHandler;
	error: boolean;
}

const regionDialog: React.FC<Props> = (props) => {
	const classes = styles();
	return (
		<Dialog
			onClose={props.onCloseModal}
			aria-labelledby="customized-dialog-title"
			open={props.open}
			className={classes.dialog}
			maxWidth="sm"
			fullWidth>
			<DialogTitle id="customized-dialog-title">Add new category</DialogTitle>
			<DialogContent className={classes.formContainer}>
				<FormControl className={classes.formControl}>
					<TextField
						error={props.error}
						label="Name"
						type="text"
						onChange={(e) =>
							props.onInputChange({
								...props.inputValues,
								name: e.target.value,
							})
						}
						value={props.inputValues.name}
					/>
				</FormControl>
				<FormControl className={classes.formControl}>
					<TextField
						error={props.error}
						label="longitude"
						type="text"
						onChange={(e) =>
							props.onInputChange({
								...props.inputValues,
								location: {
									...props.inputValues.location,
									longitude: e.target.value,
								},
							})
						}
						value={props.inputValues.location.longitude}
					/>
				</FormControl>
				<FormControl className={classes.formControl}>
					<TextField
						error={props.error}
						label="latitude"
						type="text"
						onChange={(e) =>
							props.onInputChange({
								...props.inputValues,
								location: {
									...props.inputValues.location,
									latitude: e.target.value,
								},
							})
						}
						value={props.inputValues.location.latitude}
					/>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={props.onSubmitHandler}
					color="primary"
					variant="contained"
					type="submit">
					Submit
				</Button>
				<Button onClick={props.onCloseModal} color="primary">
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default regionDialog;
