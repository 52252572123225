import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { ACCESS_TOKEN_KEY } from "../constants";
import * as routes from "../routes";

export default function PrivateRoute({ children, ...rest }: RouteProps) {
	const token = localStorage.getItem(ACCESS_TOKEN_KEY);
	return (
		<Route
			{...rest}
			render={({ location }) =>
				token ? (
					children
				) : (
					<Redirect
						to={{
							pathname: routes.BASE_PATH,
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
}
