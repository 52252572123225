import axios from "axios";
import BlogPostEntity from "../entities/blogPost";

export function postNewBlogPost(data: BlogPostEntity): Promise<any> {
  let parsedData: {
    author?: string;
    long_description: string;
    place_id: string;
  } = {
    author: data.author,
    long_description: data.longDescription,
    place_id: data.placeId,
  };

  if (data.author === "") {
    parsedData = {
      long_description: data.longDescription,
      place_id: data.placeId,
    };
  }
  return new Promise<BlogPostEntity>((resolve, reject) => {
    axios.post("/blog-post", parsedData).catch((error) => reject(error));
  });
}
