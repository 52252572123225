import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import MonthlyAccentEntity from "../../entities/monthlyAccent";

const styles = makeStyles({
	dialog: {
		width: "100%",
		height: 350,
	},
	formContainer: {
		width: "75%",
		margin: "0 auto",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	formControl: {
		width: "50%",
		padding: 0,
		marginTop: "20px",
		height: 250,
	},
	selectText: {
		textAlign: "left",
	},
});

interface Props {
	open: boolean;
	onCloseModal?: React.ReactEventHandler<{}>;
	onInputChange: Function;
	onSubmitHandler: React.ReactEventHandler;
	inputValues: MonthlyAccentEntity;
	error: boolean;
}

const monthlyAccentDialog: React.FC<Props> = (props) => {
	const classes = styles();
	let monthItems;
	const months: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

	monthItems = months.map((month: any) => {
		return (
			<MenuItem key={month} value={month}>
				{month}
			</MenuItem>
		);
	});
	return (
		<Dialog
			onClose={props.onCloseModal}
			open={props.open}
			className={classes.dialog}
			maxWidth="sm"
			fullWidth>
			<DialogTitle>Add new Monthly-Accent</DialogTitle>
			<DialogContent className={classes.formContainer}>
				<FormControl className={classes.formControl}>
					<Select
						label="category"
						error={props.error}
						name="category"
						value={props.inputValues.month}
						onChange={(e: any) =>
							props.onInputChange({
								...props.inputValues,
								month: Number(e.target.value),
							})
						}
						className={classes.selectText}>
						<MenuItem disabled>Months</MenuItem>
						{monthItems}
					</Select>
				</FormControl>
				<FormControl className={classes.formControl}>
					<TextField
						error={props.error}
						label="Blog post UUID"
						type="text"
						onChange={(
							e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
						) =>
							props.onInputChange({
								...props.inputValues,
								blogPostId: e.target.value,
							})
						}
						value={props.inputValues.blogPostId}
					/>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={props.onSubmitHandler}
					color="primary"
					variant="contained"
					type="submit">
					Submit
				</Button>
				<Button onClick={props.onCloseModal} color="primary">
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default monthlyAccentDialog;
