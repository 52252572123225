import axios from "axios";
import RegionEntity from "../entities/region";

export function getRegions(): Promise<RegionEntity[]> {
  return new Promise<RegionEntity[]>((resolve, reject) => {
    axios
      .get("/regions")
      .then(({ data }) => {
        let transformedData: RegionEntity[] = [];
        data.result.map((region: any) => {
          return transformedData.push({
            name: region.name,
            id: region.uuid,
          });
        });
        resolve(transformedData);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

interface PostRegionEntity {
  name: string;
  location: { longitude: number; latitude: number };
}

export function postRegion(data: PostRegionEntity): Promise<PostRegionEntity> {
  return new Promise<PostRegionEntity>((resolve, reject) => {
    axios
      .post("/regions", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => reject(error));
  });
}
