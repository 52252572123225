import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { getCategories, postCategory } from "../../api/categories";
import { postRegion } from "../../api/regions";
import { postSubcategory } from "../../api/subcategories";
import Category from "../../entities/category";
import CategoryDialog from "./categoryDialog";
import RegionDialog from "./regionDialog";
import SubcategoryDialog from "./subcategoryDialog";
import BlogPostDialog from "./blogPostDialog";
import { postNewBlogPost as postBlogPost } from "../../api/blogPost";
import MonthlyAccentDialog from "./monthlyAccentDialog";
import BlogPostEntity from "../../entities/blogPost";
import MonthlyAccentEntity from "../../entities/monthlyAccent";
import { postMonthlyAccent } from "../../api/monthlyAccent";

const styles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-around",
    width: "90%",
    margin: "20px auto 0 auto",
  },
});

const Main: React.FC = () => {
  const classes = styles();

  let history = useHistory();

  const [categoryName, setCategoryName] = useState<string>("");
  const [monthlyAccentProps, setMonthlyAccentProps] =
    useState<MonthlyAccentEntity>({ month: 0, blogPostId: "" });
  const [blogPostProps, setBlogPostProps] = useState<BlogPostEntity>({
    author: "",
    longDescription: "",
    placeId: "",
  });
  const [subcategoryProps, setSubcategoryProps] = useState<{
    name: string;
    categoryId: string;
  }>({
    name: "",
    categoryId: "",
  });
  const [regionProps, setRegionProps] = useState<{
    name: string;
    location: {
      longitude: string;
      latitude: string;
    };
  }>({ name: "", location: { longitude: "0", latitude: "0" } });
  const [isOpenCategoryModal, setOpenCategoryModal] = useState(false);
  const [isOpenMonthlyAccentModal, setOpenMonthlyAccentModal] = useState(false);
  const [isOpenSubcategoryModal, setOpenSubcategoryModal] = useState(false);
  const [isOpenRegionModal, setOpenRegionModal] = useState(false);
  const [isOpenBlogPostModal, setOpenBlogPostModal] = useState(false);
  const [fetchedCategories, setFetchedCategories] = useState<Category[]>([]);
  const [error, setError] = useState<boolean>(false);

  const handleModalOpenClick = (e: any) => {
    setError(false);
    setOpenCategoryModal(!isOpenCategoryModal);
  };
  const handleMonthlyAccentModalOpenClick = (e: any) => {
    setError(false);
    setOpenMonthlyAccentModal(!isOpenMonthlyAccentModal);
  };
  const handleBlogPostModalOpenClick = (e: any) => {
    setError(false);
    setOpenBlogPostModal(!isOpenBlogPostModal);
  };
  const handleRegionModalOpenClick = (e: any) => {
    setError(false);
    setOpenRegionModal(!isOpenRegionModal);
  };
  const handleSubcategoryModalOpenClick = (e: any) => {
    setError(false);
    if (!isOpenSubcategoryModal) {
      getCategories().then((data) => {
        setFetchedCategories(data);
      });
    }
    setOpenSubcategoryModal(!isOpenSubcategoryModal);
  };

  const checkValidacity = (type: string): boolean => {
    let isValid = false;
    if (type === "subcategory") {
      if (subcategoryProps.name !== "" && subcategoryProps.categoryId !== "") {
        isValid = true;
      }
    } else if (type === "category") {
      if (categoryName !== "") {
        isValid = true;
      }
    } else if (type === "region") {
      if (
        regionProps.name !== "" &&
        regionProps.location.longitude !== "0" &&
        regionProps.location.latitude !== "0"
      ) {
        isValid = true;
      }
    } else if (type === "blogPost") {
      if (
        blogPostProps.longDescription !== "" &&
        blogPostProps.placeId !== ""
      ) {
        isValid = true;
      }
    } else if (type === "monthlyAccent") {
      if (
        monthlyAccentProps.month !== 0 &&
        monthlyAccentProps.blogPostId !== ""
      ) {
        isValid = true;
      }
    }

    return isValid;
  };

  const handleBlogPostInputChange = (e: any) => {
    let newObj: BlogPostEntity = {
      ...blogPostProps,
      [e.target.name]: e.target.value,
    };
    setBlogPostProps(newObj);
  };

  const handleButtonClick = (event: any) => {
    history.push(["/", event.currentTarget.value].join(""));
  };

  const postNewSubcategory = (e: any) => {
    e.preventDefault();
    if (checkValidacity("subcategory")) {
      postSubcategory(subcategoryProps);

      setSubcategoryProps({ name: "", categoryId: "" });
    } else {
      setError(true);

      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  const postNewRegion = (e: any) => {
    e.preventDefault();
    if (checkValidacity("region")) {
      let parsedData = {
        name: regionProps.name,
        location: {
          longitude: Number(regionProps.location.longitude),
          latitude: Number(regionProps.location.latitude),
        },
      };
      postRegion(parsedData);
      setRegionProps({ name: "", location: { longitude: "0", latitude: "0" } });
    } else {
      setError(true);

      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  const postNewCategory = (e: any) => {
    e.preventDefault();
    if (checkValidacity("category")) {
      postCategory(categoryName);

      setCategoryName("");
    } else {
      setError(true);

      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  const postNewBlogPost = (e: any) => {
    e.preventDefault();
    if (checkValidacity("blogPost")) {
      postBlogPost(blogPostProps).catch((error) => {
        console.log(error);
      });

      setBlogPostProps({ author: "", longDescription: "", placeId: "" });
    } else {
      setError(true);

      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  const postNewMonthlyAccent = (e: any) => {
    e.preventDefault();
    if (checkValidacity("monthlyAccent")) {
      postMonthlyAccent(monthlyAccentProps).catch((error) =>
        console.log(error.message)
      );
      setMonthlyAccentProps({ month: 0, blogPostId: "" });
    } else {
      setError(true);

      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  return (
    <div className={classes.container}>
      <SubcategoryDialog
        open={isOpenSubcategoryModal}
        onCloseModal={handleSubcategoryModalOpenClick}
        values={subcategoryProps}
        onNameChange={setSubcategoryProps!}
        onSubmitHandler={postNewSubcategory}
        categories={fetchedCategories === undefined ? null : fetchedCategories!}
        error={error}
      />
      <CategoryDialog
        open={isOpenCategoryModal}
        onCloseModal={handleModalOpenClick}
        inputValue={categoryName}
        onNameChange={setCategoryName}
        onSubmitHandler={postNewCategory}
        error={error}
      />
      <RegionDialog
        open={isOpenRegionModal}
        onCloseModal={handleRegionModalOpenClick}
        inputValues={regionProps}
        onInputChange={setRegionProps}
        onSubmitHandler={postNewRegion}
        error={error}
      />
      <BlogPostDialog
        open={isOpenBlogPostModal}
        onCloseModal={handleBlogPostModalOpenClick}
        inputValues={blogPostProps}
        onInputChange={handleBlogPostInputChange}
        onSubmitHandler={postNewBlogPost}
        error={error}
      />

      <MonthlyAccentDialog
        open={isOpenMonthlyAccentModal}
        onCloseModal={handleMonthlyAccentModalOpenClick}
        inputValues={monthlyAccentProps}
        onInputChange={setMonthlyAccentProps}
        onSubmitHandler={postNewMonthlyAccent}
        error={error}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={(e) => handleButtonClick(e)}
        value="place"
      >
        Add Place
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={(e) => handleButtonClick(e)}
        value="route"
      >
        Add Route
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={(e) => handleBlogPostModalOpenClick(e)}
        value="region"
      >
        Add Blog Post
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={(e) => handleMonthlyAccentModalOpenClick(e)}
        value="region"
      >
        Add Monthly Accent
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={(e) => handleRegionModalOpenClick(e)}
        value="region"
      >
        Add Region
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={(e) => handleSubcategoryModalOpenClick(e)}
        value="subcategory"
      >
        Add Subcategory
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={(e) => handleModalOpenClick(e)}
        value="category"
      >
        Add Category
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={(e) => handleButtonClick(e)}
        value="statistics"
      >
        Statistics
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={(e) => handleButtonClick(e)}
        value="logout"
      >
        Logout
      </Button>
    </div>
  );
};

export default Main;
