import { Typography } from "@material-ui/core";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { fetchAllUsers } from "../../api/users";
import User from "../../entities/User";

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "firstName", headerName: "First Name", flex: 1 },
  { field: "lastName", headerName: "Last Name", flex: 1 },
  { field: "email", headerName: "Email", flex: 1 },
  { field: "savedRoutes", headerName: "Routes", width: 130, type: "number" },
  {
    field: "visitedPlaces",
    headerName: "Visited Places",
    flex: 1,
    type: "number",
  },
  {
    field: "favouritePlaces",
    headerName: "Favourite Places",
    flex: 1,
    type: "number",
  },
  { field: "providerType", headerName: "Type", width: 110 },
  {
    field: "activated",
    headerName: "Activated",
    width: 140,
    type: "boolean",
  },
  { field: "createdAt", headerName: "Created", flex: 1, type: "dateTime" },
  { field: "deletedAt", headerName: "Deleted", flex: 1, type: "dateTime" },
];

const UsersPage: React.FC = () => {
  const [allUsers, setAllUsers] = useState<User.Type[]>([]);
  useEffect(() => {
    fetchAllUsers().then((result) => {
      setAllUsers(result);
    });
  }, []);
  return (
    <div style={{ width: "100%", margin: "16px 0" }}>
      <Typography variant="h5">Users</Typography>
      <DataGrid rows={allUsers} columns={columns} autoHeight autoPageSize />
    </div>
  );
};

export default UsersPage;
