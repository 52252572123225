import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { requireBearer, setInterceptApiResponse } from "./api/login";
import "./App.css";
import LoginPage from "./components/login/index";
import Logout from "./components/logout";
import Main from "./components/main";
import { Page } from "./components/place";
import PrivateRoute from "./components/PrivateRoute";
import AddRoute from "./components/route/AddRoute";
import Statistics from "./components/Statistics";
import * as routes from "./routes";

const App: React.FC = () => {
  return (
    <div className="App">
      <Switch>
        <Route exact path={routes.BASE_PATH}>
          <LoginPage />
        </Route>
        <PrivateRoute>
          <Route
            path={routes.MAIN_PATH}
            render={() => {
              requireBearer();
              setInterceptApiResponse();
              return <Main />;
            }}
          />
          <Route exact path={routes.STATISTICS_ROUTE}>
            <Statistics />
          </Route>
          <Route exact path={routes.LOGOUT_PATH}>
            <Logout />
          </Route>
          <Route exact path={routes.ADD_PLACE_PATH}>
            <Page />
          </Route>
          <Route exact path={routes.ADD_ROUTE_PATH}>
            <AddRoute />
          </Route>
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default withRouter(App);
