import axios from "axios";
import PlaceEntity from "../entities/place";
import { GetPlaceEntity } from "../entities/place";

export function postPlace(data: PlaceEntity): Promise<PlaceEntity> {
  const parsedData = {
    name: data.name,
    description: data.description,
    pictures: data.pictures.map((picture) =>
      encodeURI(`https://cdn.goxplore.bg/media/${picture}`)
    ),
    exclude_from_route: data.addToRoute,
    category_id: data.categoryId,
    subcategory_id: data.subcategoryId,
    cover_photo: encodeURI(`https://cdn.goxplore.bg/media/${data.coverPhoto}`),
    region_id: data.regionId,
    location: {
      ...data.location,
    },
  };
  console.log(parsedData);
  return new Promise<PlaceEntity>((resolve, reject) => {
    axios
      .post("/places", parsedData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPlaces(): Promise<GetPlaceEntity[]> {
  return new Promise<GetPlaceEntity[]>((resolve, reject) => {
    axios
      .get("/places?include_fields=name&include_fields=uuid")
      .then(({ data }) => {
        const parsedData: GetPlaceEntity[] = [];
        data.result.map((place: any) => {
          parsedData.push({
            name: place.name,
            uuid: place.uuid,
          });
        });
        resolve(parsedData);
      })
      .catch((error) => reject(error));
  });
}
