import axios from "axios";
import Feedback from "../entities/Feedback";

export function fetchAllFeedbacks(): Promise<Feedback.Type[]> {
  return new Promise<Feedback.Type[]>((resolve, reject) => {
    axios
      .get("/feedback")
      .then(({ data: { result } }) => {
        resolve(result.map(Feedback.parse));
      })
      .catch((error) => reject(error));
  });
}
